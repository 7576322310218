import React from 'react'

import { firebase } from '../data/providers'
import { env } from '../main/config/env'

export type ConfigValues = {
  ENABLE_CASHBACK: boolean
  UPSELL_SPECIALIST_NAME: string
  WEB_TAB_CHANGE_PAYMENT: boolean
  WEB_TAB_CHANGE_PAYMENT_EXPIRES_AT: boolean
  WEB_READJUST: boolean
  WEB_B2C_SET_DUE_DATE_CONTRACT: boolean
  B2C_UPSELL: boolean
  B2C_MEMORE: boolean
  B2C_CHANGE_PAYMENT_ENABLED: boolean
}

const CONFIG_DEFAULTS: ConfigValues = {
  ENABLE_CASHBACK: false,
  UPSELL_SPECIALIST_NAME: JSON.stringify({
    name: 'Gabriela',
    imageUrl:
      'https://arita.com.br/wp-content/uploads/2020/08/pessoa-expansiva-principais-caracteristicas-desta-personalidade.jpg',
  }),
  WEB_TAB_CHANGE_PAYMENT: false,
  WEB_TAB_CHANGE_PAYMENT_EXPIRES_AT: false,
  WEB_READJUST: false,
  WEB_B2C_SET_DUE_DATE_CONTRACT: false,
  B2C_UPSELL: false,
  B2C_MEMORE: false,
  B2C_CHANGE_PAYMENT_ENABLED: false,
}

const CONFIG_DEFAULTS_DEV: ConfigValues = {
  ENABLE_CASHBACK: true,
  UPSELL_SPECIALIST_NAME: JSON.stringify({
    name: 'Gabriela',
    imageUrl:
      'https://arita.com.br/wp-content/uploads/2020/08/pessoa-expansiva-principais-caracteristicas-desta-personalidade.jpg',
  }),
  WEB_TAB_CHANGE_PAYMENT: true,
  WEB_TAB_CHANGE_PAYMENT_EXPIRES_AT: true,
  WEB_READJUST: true,
  WEB_B2C_SET_DUE_DATE_CONTRACT: true,
  B2C_UPSELL: true,
  B2C_MEMORE: true,
  B2C_CHANGE_PAYMENT_ENABLED: true,
}

type ConfigKey = keyof ConfigValues

type ConfigValue = string | number | boolean

export interface ConfigContextValue {
  get: <T = ConfigValue>(key: ConfigKey) => T
}

export const ConfigContext = React.createContext<ConfigContextValue>(
  {} as ConfigContextValue,
)

export const RemoteConfigProvider: React.PropsWithChildren<any> = ({
  children,
}) => {
  const value = useRemoteConfigProvider()
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  )
}

export const useRemoteConfig = () => {
  return React.useContext(ConfigContext)
}

function useRemoteConfigProvider(): ConfigContextValue {
  const isServer = typeof window === 'undefined'

  const [configClient, setConfigClient] =
    React.useState<firebase.remoteConfig.RemoteConfig | null>(null)

  async function initRemoteConfig() {
    const remoteConfig = firebase?.remoteConfig()
    remoteConfig.defaultConfig = { ...CONFIG_DEFAULTS }
    remoteConfig.settings.minimumFetchIntervalMillis = 450000 // 7.5 minutes
    await remoteConfig.fetchAndActivate().catch(console.warn)
    setConfigClient(remoteConfig)
  }

  React.useEffect(() => {
    if (!configClient) initRemoteConfig()
  }, [configClient])

  const get = <T extends any>(key: keyof ConfigValues): T => {
    if (!env.isProduction) return CONFIG_DEFAULTS_DEV[key] as T

    if (isServer || !configClient) {
      console.warn(
        'Cannot call configContext.get() outside of a browser context. Using local default',
      )
      return CONFIG_DEFAULTS[key] as T
    }

    const defaultValue = CONFIG_DEFAULTS[key]

    if (typeof defaultValue === 'boolean') {
      return configClient.getBoolean(key) as T
    } else if (typeof defaultValue === 'number') {
      return configClient.getNumber(key) as T
    } else {
      return configClient.getString(key) as T
    }
  }

  return { get }
}
